'use strict';

var core = require('integrations/product/base');
var toastHelpers = require('core/components/toast');

/**
 * Append the gallery images to the other image types except gallery & swatch
 * @param images
 */
function appendGalleryImages(images) {
    if (images.gallery && images.gallery.length > 0) {
        Object.keys(images).forEach(function (key) {
            if (key !== 'gallery' && key !== 'swatch') {
                Array.prototype.push.call(images[key], ...images.gallery);
            }
        })
    }
}

// override to maintain markup structure required for wishlist button and zoom customizations
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVariant;
    var isSetItem = $productContainer.hasClass('product-set-item-detail') ? true : false;
    appendGalleryImages(response.product.images);

    if (response.product.variationAttributes) {
        this.updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
        isVariant = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVariant) {
            $productContainer.parent('.bonus-product-item').data('pid', response.product.id);
            $productContainer.parent('.bonus-product-item').data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update primary images
    var primaryImages = response.product.images;
    var pdpGalleryAssets = response.product.pdpGalleryAssets;
    var $oldWishlistIcon = $productContainer.find('div.slide .product-list-enhancements-toggle-product').first().clone(true);

    this.createSlider(primaryImages, pdpGalleryAssets, $productContainer.find('.primary-images'));
    // if variant is a product set item, update the sample image
    if (isSetItem) {
        $productContainer
            .find('.product-set-item-main-image')
            .attr('src', primaryImages.large[0].url)
            .attr('alt', primaryImages.large[0].alt);
    }

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $('.prices .price', $productContainer).length
            ? $('.prices .price', $productContainer)
            : $('.prices .price');
        $priceSelector.replaceWith(response.product.price.html);
    }

    // Update promotions
    $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);

    this.updateAvailabilityProcess(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty().html(this.getAttributesHtml(response.product.attributes));

    // Update wishlist (org_km overrides here)
    if ($oldWishlistIcon && $oldWishlistIcon.length) {
        var oldProductName = $oldWishlistIcon.attr('data-productname');
        var newProductName = response.product.productName;
        var $newWishlistIcon = $oldWishlistIcon;

        $newWishlistIcon.attr('data-wishlistpid', response.product.wishlistpid);
        $newWishlistIcon.attr('data-addmessage', $newWishlistIcon.attr('data-addmessage').replace(oldProductName, newProductName));
        $newWishlistIcon.attr('data-removemessage', $newWishlistIcon.attr('data-removemessage').replace(oldProductName, newProductName));
        $newWishlistIcon.attr('data-productname', newProductName);

        //Make heart icon accurate
        var wishlist = require('core/productListEnhancements/helpers.js');
        wishlist.updateLinkData($newWishlistIcon);

        var $newSliderMainImages = $productContainer.find('div.primary-images-main div.slide img');
        $newSliderMainImages.each((_i, newImage) => {
            var $newImage = $(newImage);
            $newImage.closest('.zoom-image-container').after($newWishlistIcon.clone(true));
        });
    }

    $('.highlighted-swatch-carousel-badge', $productContainer).remove();

    if (response.product.highlightedSwatches && response.product.highlightedSwatches.length) {
        var $selectedSwatch = $('.swatch-value.selected', $productContainer);
        var attrValue = $selectedSwatch.attr('data-attr-value');
        if (response.product.highlightedSwatches.includes(attrValue)) {
            var $img = $selectedSwatch.find('.highlighted-swatch-img');

            $('.primary-images .primary-images-main', $productContainer).append(`
                <div class="badge-product-container badge-image-over justify-content-center highlighted-swatch-carousel-badge">
                <span class="badge-product background--">
                    <img class="badge-icon" src="${$img.attr('src')}">
                </span>
                </div>
                `);
        }
    }
}

// override for the stepper buttons to use the stepQuantity from the product
function bindQuantityStepperButtons($stepper) {
    var scope = this;
    var min = parseInt($stepper.data('min'));
    var max = parseInt($stepper.data('max'));

    $stepper.find('button').off('click').click(event => {
        event.preventDefault();
        var $button = $(event.target);
        var action = $button.data('action');
        var previousValue = parseInt($stepper.find('input').val());
        var newValue = previousValue;
        var stepQuantity = parseInt($stepper.data('stepquantity'));

        if (action === 'increase' && (previousValue + stepQuantity <= max)) {
            newValue = newValue + stepQuantity;
        }
        if (action === 'decrease' && (previousValue - stepQuantity >= min)) {
            newValue = newValue - stepQuantity;
        }
        if (newValue !== previousValue) {
            $stepper.find('input').prop('value', newValue).prop('data-qty', newValue).trigger('change');
            scope.updateQuantityStepperDisabledStates($stepper);
            $('body').trigger('quantityStepper:change', $stepper);
        }
    });
}

// override for the stepper input to use the stepQuantity from the product
function bindQuantityStepperInput($stepper) {
    var scope = this;

    var min = parseInt($stepper.data('min'));
    var max = parseInt($stepper.data('max'));
    var stepQuantity = parseInt($stepper.data('stepquantity'));

    $stepper.find('input').off('change').change(event => {
        var $input = $(event.target);
        var previousValue = !isNaN(parseInt($input.prop('data-qty'))) ? parseInt($input.prop('data-qty')) : parseInt($input.attr('data-qty'));
        var newValue = parseInt($input.val());
        // round the number up to nearest multiple of the product step quantity
        newValue = Math.ceil((newValue) / stepQuantity) * stepQuantity;

        if (!isNaN(newValue) && newValue <= max && newValue >= min) {
            $input.prop('value', newValue).prop('data-qty', newValue);
            scope.updateQuantityStepperDisabledStates($stepper);
            $('body').trigger('quantityStepper:change', $stepper);
        } else {
            $input.prop('value', previousValue);
        }
    });
}

function updateQuantityStepperDisabledStates($stepper) {
    var min = parseInt($stepper.attr('data-min'));
    var max = parseInt($stepper.attr('data-max'));
    var stepQuantity = parseInt($stepper.attr('data-stepquantity'));
    var $input = $stepper.find('input');
    var $minusButton = $stepper.find('[data-action="decrease"]');
    var $plusButton = $stepper.find('[data-action="increase"]');
    var value = !isNaN(parseInt($input.prop('data-qty'))) ? parseInt($input.prop('data-qty')) : parseInt($input.attr('data-qty'));

    if (value <= min) {
        $minusButton.addClass('disabled');
    } else {
        $minusButton.removeClass('disabled');
    }
    // if value is greater than or equal to max OR would be greater than max based on step quantity
    if (value >= max || value + stepQuantity > max) {
        $plusButton.addClass('disabled');
    } else {
        $plusButton.removeClass('disabled');
    }
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
 function getQuantitySelector($el) {
    var quantitySelected;
    if ($el && $('.set-items').length) {
        quantitySelected = $($el).closest('.product-detail').find('.quantity-stepper input');
    } else if ($el && $('.product-bundle').length) {
        var quantitySelectedModal = $($el).closest('.modal-footer').find('.quantity-stepper input');
        var quantitySelectedPDP = $($el).closest('.bundle-footer').find('.quantity-stepper input');
        if (quantitySelectedModal.val() === undefined) {
            quantitySelected = quantitySelectedPDP;
        } else {
            quantitySelected = quantitySelectedModal;
        }
    } else if (($el).hasClass('order-history-add-to-cart')) {
        quantitySelected = $($el).parent().find('.quantity-stepper')
    } else if ($el.closest('.modal-footer').length) {
        quantitySelected = $el.closest('.modal-footer').find('.quantity-stepper input');
    } else {
        quantitySelected = $('.quantity-stepper input');
    }
    return quantitySelected;
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
 function getQuantitySelected($el) {
    return this.getQuantitySelector($el).val();
}

/**
 * Updates the quantities across the different step quantities for products
 * On variant change, resets quantity to the step quantity
 * @param {*} stepQuantity
 * @param {*} $quantityStepper
 */
function updateStepperQuantities(stepQuantity, $quantityStepper) {
    var oldStep = $quantityStepper.data('stepquantity');
    // Update the min and stepquantity on input if changed
    if (oldStep !== stepQuantity) {
        $quantityStepper.attr('data-stepquantity', stepQuantity).data('stepquantity', stepQuantity);
        $quantityStepper.attr('data-min', stepQuantity).data('min', stepQuantity);
    }
    // reset all quantity values to the step quantity
    var qtyInput = $($quantityStepper).find('input');
    qtyInput.val(stepQuantity);
    qtyInput.attr('data-qty', stepQuantity);
    qtyInput.prop('data-qty', stepQuantity);
}

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities - the quantity values for the product
 * @param {*} stepQuantity - the step quantity value for the product
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {*} quantityChange - optional bool for if the update is for quantity changing
 */
 function updateQuantities(quantities, stepQuantity, $productContainer, quantityChange) {
    if ($productContainer.parent('.bonus-product-item').length <= 0) {
        var quantityStepper = $productContainer.find('.quantity-stepper');
        if (!quantityChange) {
            updateStepperQuantities(stepQuantity, quantityStepper);
        }
    }
}
/**
 * Customization to update product name on variant change
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.productName - Product Name
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function updateProductName(response, $productContainer) {
    var productName = response.product.productName;

    if (productName) {
        $productContainer.find('.product-name, .set-item-product-name').empty().html(productName);
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
 function attributeSelect(selectedValueUrl, $productContainer) {
    var methods = this;
    var quantityChange = event != undefined && ($(event.target).hasClass('icon-plus') || $(event.target).hasClass('icon-minus') || $(event.target).is('input[name="qty-stepper"]'));

    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect',
            { url: selectedValueUrl, container: $productContainer });

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                methods.handleVariantResponse(data, $productContainer);
                methods.updateProductName(data, $productContainer);
                methods.updateOptions(data.product.optionsHtml, $productContainer);
                methods.updateQuantities(data.product.quantities, data.product.stepQuantity, $productContainer, quantityChange);
                var quantityStepper = $productContainer.find('.quantity-stepper');
                methods.updateQuantityStepperDisabledStates(quantityStepper);
                methods.bindQuantityStepperButtons(quantityStepper);
                methods.bindQuantityStepperInput(quantityStepper);
                $('body').trigger('product:afterAttributeSelect',
                    { data: data, container: $productContainer });
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    var methods = this;

    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog choose-bonus-product-dialog" '
        + 'data-total-qty="' + data.maxBonusItems + '"'
        + 'data-UUID="' + data.uuid + '"'
        + 'data-pliUUID="' + data.pliUUID + '"'
        + 'data-addToCartUrl="' + data.addToCartUrl + '"'
        + 'data-pageStart="0"'
        + 'data-pageSize="' + data.pageSize + '"'
        + 'data-moreURL="' + data.showProductsUrlRuleBased + '"'
        + 'data-bonusChoiceRuleBased="' + data.bonusChoiceRuleBased + '">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <h5 class="modal-title">' + data.labels.selectprods + '</h5>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);

    $('#chooseBonusProductModal').on('hidden.bs.modal', function (e) {
        var hasBonusProductsSelected = ($('.bonus-summary-products-container .selected-pid').length > 0)
        if ($('.cart-page').length && !hasBonusProductsSelected) {
            location.reload();
        }
      })

    $('.modal-body').spinner().start();


    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
            var parsedHtml = methods.parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            chooseBonusProductModal
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

function selectBonusProduct() {
    $(document).on('click', '.select-bonus-product', function () {
        var $choiceOfBonusProduct = $(this).parents('.choice-of-bonus-product');
        var pid = $(this).data('pid');
        var maxPids = $('.choose-bonus-product-dialog').data('total-qty');
        var submittedQty = parseInt($choiceOfBonusProduct.find('.bonus-quantity .quantity-stepper input').val(), 10);
        var totalQty = 0;
        var isAlreadySelected = false;

        $.each($('#chooseBonusProductModal .selected-bonus-products .selected-pid'), function () {
            totalQty += $(this).data('qty');

            if ($(this).data('pid') === pid) {
                isAlreadySelected = true; // prevent same bonus item from being added twice
            }
        });

        totalQty += submittedQty;
        var optionID = $choiceOfBonusProduct.find('.product-option').data('option-id');
        var valueId = $choiceOfBonusProduct.find('.options-select option:selected').data('valueId');

        if (isAlreadySelected) {
            var limitMessage = $(this).closest('.detail-panel').find('.bonus-quantity').data('limit-message');
            if (limitMessage) {
                toastHelpers.methods.show('danger', limitMessage, false);
            }
            $('.selected-bonus-products .bonus-summary').addClass('alert-danger');
        } else if (totalQty <= maxPids) {
            var selectedBonusProductHtml = ''
            + '<div class="selected-pid" '
            + 'data-pid="' + pid + '"'
            + 'data-qty="' + submittedQty + '"'
            + 'data-optionID="' + (optionID || '') + '"'
            + 'data-option-selected-value="' + (valueId || '') + '"'
            + '>'
            + '<div class="bonus-product-name">'
            + $choiceOfBonusProduct.find('.product-name').html()
            + '</div>'
            + '<div class="remove-bonus-product"></div>'
            + '</div>'
            ;
            $('#chooseBonusProductModal .selected-bonus-products .bonus-summary-products-container').append(selectedBonusProductHtml);
            $('.pre-cart-products').html(totalQty);
            $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
            $('body').trigger('modal:loaded', $('#chooseBonusProductModal')); // update quickview modal scroll height
        } else {
            var maxSelectedMessage = $(this).closest('.detail-panel').find('.bonus-quantity').data('max-selected-message');
            if (maxSelectedMessage) {
                toastHelpers.methods.show('danger', maxSelectedMessage, false);
            }
            $('.selected-bonus-products .bonus-summary').addClass('alert-danger');
        }
    });
}

function enableQuantitySteppersListener() {
    var $body = $('body');

    $body.on('quantitySteppers:Enable', function() {
        core.enableQuantitySteppers($body);
    });
}

function enableQuantitySteppers($context = $('body')) {
    var scope = this;
    var $steppers = $context.find('.quantity-stepper');
    if ($steppers.length) {
        $steppers.each((index, stepper) => {
            var $stepper = $(stepper);
            scope.methods.updateQuantityStepperDisabledStates($stepper);
            scope.methods.bindQuantityStepperButtons($stepper);
            scope.methods.bindQuantityStepperInput($stepper);
        });
    }
}

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

function availability() {
    $(document).on('change', '.quantity-stepper input', function (e) {
        e.preventDefault();

        var $productContainer = $(this).closest('.product-detail');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.modal-content').find('.product-quickview');
        }
        let pid = $productContainer.data('pid');
        let quantity = $(e.currentTarget).closest('.quantity-stepper').find('input').val();

        if ($('.bundle-items', $productContainer).length === 0) {
            let url = appendToUrl($(e.currentTarget).data('url'), {
                pid: pid,
                quantity: quantity
            });

            core.methods.attributeSelect(url,
                $productContainer);
        }
    });
}

/**********
     * shared across QV and PDP
     */
function addToCart() {
    var scope = this;

    // if qty stepper input has focus, we need to blur it so product data can update before button click
    $(document).on('mouseenter', 'button.add-to-cart, button.add-to-cart-global', function (event) {
        var $button = $(event.target);
        var $container = $button.closest('.product-detail');
        if (!$container.length) {
            $container = $button.closest('.quick-view-dialog');
        }
        var $qtyStepperInput = $container.find('.quantity-stepper input');

        if ($qtyStepperInput.length && $qtyStepperInput.is(':focus')) {
            $qtyStepperInput.blur();
        }
    });

    $(document).on('click', 'button.add-to-cart, button.add-to-cart-global, a.build-a-bundle, a.build-a-bundle-submit', function (event) {
        var addToCartUrl;
        var pid;
        var pidsObj;
        var setPids;
        var quantity;

        $('body').trigger('product:beforeAddToCart', this);

        var newPids = $('.build-a-bundle-items').attr('data-pids') !== "" ? $('.build-a-bundle-items').attr('data-pids') + ',' + $(this).data('pid') : $(this).data('pid');

        if (($('.set-items').length && $(this).hasClass('add-to-cart-global')) || $('.build-a-bundle-items').length) {
            setPids = [];

            var $products = $(this).closest('.product-detail').find('.product-set-item-detail');
            if (!$products.length) {
                if ($(this).closest('.quick-view-dialog').length) {
                    $products = $(this).closest('.quick-view-dialog').find('.product-set-item-detail');
                } else {
                    if ($(this).parentsUntil('.experience-commerce_layouts-1column').length && !$('.build-a-bundle-items').length) {
                        $products = $(this).parentsUntil('.experience-commerce_layouts-1column').find('.product-detail');  // pagedesigner component 'Add all to cart btn' won't have .product-set-item-detail classes
                    } else if ($('.build-a-bundle-items').length && $(this).data('pid')) {
                        $('.build-a-bundle-items').attr('data-pids', newPids);
                        $('.build-a-bundle-submit').text("ADD BUNDLE TO CART:" + newPids);
                    }
                }
            }
            if (!$products.length && $('.build-a-bundle-items').length) {
                $products = $('.build-a-bundle-items').attr('data-pids').split(',');
                for(var i = 0; i < $products.length; i++) {
                    setPids.push({
                        pid: $products[i],
                        options: "[]"
                    });
                }
            } else {
                $products.each(function () {
                    if (!$(this).hasClass('product-set-detail')) {
                        setPids.push({
                            pid: $(this).find('.product-id').text(),
                            qty: $(this).find('.quantity-select').val(),
                            options: scope.methods.getOptions($(this))
                        });
                    }
                });
            }

            pidsObj = JSON.stringify(setPids);
        }


        pid = scope.methods.getPidValue($(this));

        quantity = $(this).hasClass('single-variant-quick-add-to-cart') ? 1 : scope.methods.getQuantitySelected($(this));

        var $productContainer = $(this).closest('.product-detail');
        if (!$productContainer.length) {
            if ($(this).hasClass('single-variant-quick-add-to-cart') || $(this).hasClass('build-a-bundle-submit')) {
                addToCartUrl = $(this).data('url');
            } else {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
                var $productModalbody = $(this).closest('.modal-content');
                addToCartUrl = scope.methods.getAddToCartUrl($productModalbody);
            }
        } else {
            addToCartUrl = scope.methods.getAddToCartUrl($productContainer);
        }

        var form = {
            pid: pid,
            pidsObj: pidsObj,
            childProducts: scope.methods.getChildProducts(),
            quantity: quantity,
            options: scope.methods.getOptions($productContainer)
        };

        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            if($(this).hasClass('build-a-bundle-submit')) {
                var couponUrl = $(this).data('coupon-url');
                var csrfToken = window.UncachedData.csrf?.token;
                var csrfTokenName = window.UncachedData.csrf?.tokenName;
                var csrfData = {[csrfTokenName]: csrfToken};
                $.ajax({
                    url: couponUrl,
                    method: 'GET',
                    data: csrfData,
                    success: function (unusedData) {
                        $.ajax({
                            url: addToCartUrl,
                            method: 'POST',
                            data: form,
                            success: function (data) {
                                scope.methods.handlePostCartAdd(data);
                                $('body').trigger('product:afterAddToCart', data);
                                $('body').trigger('product:afterAddToCartQuickview', data); //cart page quickview only
                                $.spinner().stop();
                                scope.methods.miniCartReportingUrl(data.reportingURL);
                            },
                            error: function () {
                                $.spinner().stop();
                            }
                        });
                    },
                    error: function () {
                        toastHelpers.methods.show('danger', 'There was an issue getting your discount for building a bundle', false);
                        $.spinner().stop();
                    }
                });
            } else {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        scope.methods.handlePostCartAdd(data);
                        $('body').trigger('product:afterAddToCart', data);
                        $('body').trigger('product:afterAddToCartQuickview', data); //cart page quickview only
                        $.spinner().stop();
                        scope.methods.miniCartReportingUrl(data.reportingURL);
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        }
    });
}

core.addToCart = addToCart;
core.availability = availability;
core.methods.handleVariantResponse = handleVariantResponse;
core.methods.bindQuantityStepperButtons = bindQuantityStepperButtons;
core.methods.bindQuantityStepperInput = bindQuantityStepperInput;
core.methods.chooseBonusProducts = chooseBonusProducts;
core.methods.updateQuantityStepperDisabledStates = updateQuantityStepperDisabledStates;
core.methods.getQuantitySelected = getQuantitySelected;
core.methods.getQuantitySelector = getQuantitySelector;
core.methods.updateQuantities = updateQuantities;
core.methods.attributeSelect = attributeSelect;
core.methods.updateProductName = updateProductName;
core.selectBonusProduct = selectBonusProduct;
core.enableQuantitySteppers = enableQuantitySteppers;
core.enableQuantitySteppersListener = enableQuantitySteppersListener;

module.exports = core;