'use strict';

var core = require('core/components/slider');

var toggleZoomClass = function() {
    $(this).toggleClass('zoomed');
}

// Override core to zoom on click instead of hover
core.enableImageZoom = function($sliderContainer) {
    if ($sliderContainer.closest('.primary-images').length
        && !$sliderContainer.hasClass('slider-thumbnails')
        && !window.isMobile()) {
        var $slides = $sliderContainer.find('.slide');
        $slides.each((_index, slide) => {
            var $slide = $(slide);
            var $zoomContainer = $slide.find('.zoom-image-container');

            if (!$slide.hasClass('no-zoom')) {
                var zoomImageUrl = $slide.data('zoom-image') || $slide.find('img').attr('src');
                $zoomContainer.zoom({
                    url: zoomImageUrl,
                    magnify: 1,
                    touch: false,
                    on: 'click',
                    onZoomIn: toggleZoomClass,
                    onZoomOut: toggleZoomClass
                });
            }
        });
    }
}

// Override to wrap images in a div with class zoom-image-container
core.getUpdatedSlideItems = function(images, assets, isThumbnail) {
    var slideItems = [];

    images['large'].forEach(image => {
        var $slideElement = $('<div></div>').attr('class', 'slide');
        var $imageElement = $('<img/>');
        var $imageContainer = $slideElement;

        if (!isThumbnail) {
            $slideElement.attr('data-zoom-image', images['hi-res'].length ? images['hi-res'][image.index].url : images['large'][image.index].url);

            $imageContainer = $('<div></div>').attr('class', 'zoom-image-container');
            $slideElement.append($imageContainer);
        }

        $imageElement.attr({
            'src': image.url,
            'alt': image.alt + ' image number ' + (parseInt(image.index) + 1).toFixed(0),
            'class': 'd-block img-fluid',
            'itemprop': 'image'
        });

        $imageContainer.append($imageElement);
        slideItems.push($slideElement[0]);
    });

    core.getPDPGalleryAssetSlides(slideItems, assets, isThumbnail);

    return slideItems;
}

module.exports = core;
